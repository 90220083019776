<template>
<div>
  <div id="hotnews-title">
    <router-link :to="`/hotNews`">热点新闻</router-link>
  </div>
  <div class="hotnews-item"
    v-for="item,index in this.list" :key='index'
  >
    <span><img :src="require('../../../assets/list0' + (index + 1) + '.png')"></span>
    <router-link :to="`/hotnews/` + index">
      {{ item.title }}
    </router-link>
    <!-- <router-link :to="{ path: '/hotnews/info', query: {id:index }}">
      {{ item.title }}
    </router-link> -->
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    }
  },
  beforeMount() {
    let obj = this.$store.state.hotNewsList;
    for (let i = 0; i < obj.length; i++) {
      this.list.push( {
        title: obj[i].title,
      })
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.clear {
  content: "";
	clear: both;
	content: '';
	display: block;
	width: 0;
	height: 0;
	visibility: hidden;
}
div#hotnews-title {
  border-top: 1px solid #e9e9e9;
  margin-bottom: 2.25rem;
}
div#hotnews-title > a {
  border-top: 1px solid #0066e4;
  padding-top: 0.9375rem;
  display: table;
  font-size: 1.125rem;
  color: #333333;
  font-weight: bold;
  text-decoration: none;
  outline: none;
}
div.hotnews-item {
  margin-bottom: 1.5rem;
  font-size: 14px;
  line-height: 1.25rem;
  height: 1.25rem;
  overflow: hidden;
}
div.hotnews-item > span {
  line-height: 1.25rem;
  height: 1.25rem;
}
div.hotnews-item > span > img {
  vertical-align: middle
}
div.hotnews-item > a {
  color: #666666;
  transition: 0.6s all;
  text-decoration: none;
  outline: none;
  font-size: 14px;
  line-height: 1.25rem; 
}
div.hotnews-item:hover > a {
  color: #1875f0;
  transition: 0.6s all;
}
div#content-right {
  float: right;
width: 26%;
}
</style>